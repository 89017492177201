function terminate_billing_clearSessionStorage(tag) {
    let prefix = sessionStorage.getItem('keep_address');             
    if (prefix == null) return;
    
    sessionStorage.removeItem(prefix+tag);             
}

terminate_billing_clearSessionStorage('alias_name');
terminate_billing_clearSessionStorage('postal_code2');
terminate_billing_clearSessionStorage('address2');
sessionStorage.removeItem('keep_address');             
//sessionStorage.clear()；